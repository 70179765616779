
import { Component, Vue, Prop } from 'vue-property-decorator';
import { QSkeleton } from 'quasar';

import { cloneDeep } from 'lodash-es';
import WsRadioList from '@WS_Components/WsRadioList.vue';
import PricePeriod from '@/components/PricePeriod.vue';

@Component({
  components: {
    QSkeleton,
    WsRadioList,
    PricePeriod,
  },
})
export default class PropertyRadio extends Vue {
  @Prop(String)
  readonly path!: string;
  @Prop({ default: () => ({}) })
  readonly value!: any;
  @Prop({ default: () => [] })
  readonly options!: any;
  @Prop({ default: 12 })
  readonly period!: any;
  @Prop(String)
  readonly link!: string;
  @Prop(Boolean)
  readonly inEdit!: boolean;
  @Prop(Boolean)
  readonly hideFree!: boolean;
  @Prop({ default: true })
  readonly noSpacingX!: boolean;
  @Prop({ default: true })
  readonly noSpacingY!: boolean;

  get model() {
    return cloneDeep(this.value);
  }
  set model(model: any) {
    if (!this.inEdit) {
      // not to change model with custom price, different
      // then in options https://loopiagroup.atlassian.net/browse/PNO-2174
      return;
    }
    this.$emit('input', model);
  }
}
