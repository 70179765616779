
import { Component, Prop, Vue } from 'vue-property-decorator';
import { QCard, QCardSection, QIcon, QSkeleton } from 'quasar';

import { getSnakedKeyFactory } from '@loopia-group/utils';
import { isNumber } from 'lodash-es';
import WsList from '@WS_Components/WsList.vue';
import WsIcon from '@WS_Components/WsIcon.vue';
import { CartItemInfo } from '@/store/const';
import PricePeriod from '@/components/PricePeriod.vue';
import { resolvePrice } from '@/utilities';
import { ProductPrice } from '@/types';

@Component({
  components: {
    QCard,
    QCardSection,
    QIcon,
    QSkeleton,
    WsList,
    WsIcon,
    PricePeriod,
  },
})
export default class PropertiesSummary extends Vue {
  @Prop({ required: true })
  readonly properties!: string[];
  @Prop()
  readonly info!: CartItemInfo;
  @Prop({ type: String, default: 'product.properties.summary.title' })
  readonly title!: string;
  @Prop({ default: () => ({}) })
  readonly model!: any;
  @Prop({ default: 'product.properties' })
  readonly path!: string;
  @Prop({ default: 0 })
  readonly additionalPrice!: number;
  @Prop({ default: null })
  readonly defaultPrice!: number;
  @Prop(Boolean)
  readonly simpleItems!: boolean;

  get loading() {
    return !this.info || !this.properties;
  }

  get period() {
    return this.info?.period ?? this.model?.period;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  get items() {
    if (this.loading) {
      return [];
    }

    const items: any[] = [];
    const model = this.model;
    const period = this.period;

    this.properties.forEach(property => {
      const propertyModel = model[property];

      if (!property || (!propertyModel && !this.simpleItems)) {
        return;
      }

      /* PRICE */
      let price = resolvePrice(propertyModel?.prices, period);
      // fallback to price
      if (price === undefined) {
        price = resolvePrice(propertyModel?.price, period);
      }

      /* TRANSLATION */
      const tKeys = [property];
      if (propertyModel?.type) {
        tKeys.push(propertyModel.type);
      }
      const mainKey = this.getKey(...tKeys, 'label');
      let fallback = this.getKey(property, 'label');
      if (fallback === mainKey) {
        fallback = this.getKey(property);
      }

      /* WRAPPING */
      const item = {
        i_label: mainKey,
        i_label_values: {
          _fallback: fallback,
          ...propertyModel,
        },
        i_price: price ?? this.defaultPrice,
        ...propertyModel,
      };

      /* EXCEPTION FOR OS LICENCES HANDLING */
      if (property === 'os') {
        item.i_label = propertyModel?.name;
        item.i_label_values._fallback = undefined;
        if (model.licences?.length) {
          item.i_price += model.licences.reduce(
            (acc: number, licence: any) =>
              (acc += resolvePrice(licence.prices, period) || 0),
            0
          );
        } else {
          item.i_price = price ?? 0;
        }
      }

      /* EXCEPTION FOR VOLUMETRICAL PROPERTIES */
      if (item.i_price && propertyModel.volume !== undefined) {
        // multiply by volume
        item.i_price *= propertyModel.volume;
      }

      items.push(item);
    });

    return items;
  }

  get totalPrice() {
    if (this.loading) {
      return null;
    }
    let total = this.items.reduce((acc: number | null, item: any) => {
      if (acc === null || item.i_price === null) {
        acc = null;
      } else {
        acc += item.i_price;
      }
      return acc;
    }, (resolvePrice(this.info?.prices || (this.info?.price as ProductPrice), this.period) as any) || 0);
    if (this.additionalPrice && isNumber(total)) {
      total += this.additionalPrice;
    }

    //Dedicated server paid by month. Have to be paid initially for 3 months
    if (this.$route.query.period === '1') {
      total *= 3;
    }

    return total;
  }

  getKey(...props: string[]): string {
    this.getKey = getSnakedKeyFactory(this.path);
    return this.getKey(...props);
  }
}
