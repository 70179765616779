
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { QItem, QItemLabel, QItemSection } from 'quasar';

import { getSnakedKeyFactory, simpleWrapperCmp } from '@loopia-group/utils';
import WsInput from '@WS_Components/WsInput.vue';
import WsRadioList from '@WS_Components/WsRadioList.vue';
import WsSelect from '@WS_Components/WsSelect.vue';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import PricePeriod from '@/components/PricePeriod.vue';

@Component({
  components: {
    QItem,
    QItemLabel,
    QItemSection,
    WsInput,
    WsRadioList,
    WsSelect,
    PricePeriod,
  },
})
export default class PropertyOs extends Vue {
  @Prop({ required: true })
  readonly value!: any;
  @Prop({ default: () => [] })
  readonly options!: any;
  @Prop({ default: () => [] })
  readonly licences!: any;
  @Prop({ default: 12 })
  readonly period!: number;
  @Prop(Boolean)
  readonly licenseOnly!: boolean;
  @Prop(Boolean)
  readonly inEdit!: boolean;

  WsCheckbox = WsCheckbox;
  ListItemCmp = simpleWrapperCmp();

  get model() {
    if (typeof this.value === 'string') {
      return { name: this.value };
    }
    return { ...this.value };
  }
  set model(value: any) {
    this.reEmit(value);
  }

  get licencesModel() {
    return [...this.licences];
  }
  set licencesModel(value: any) {
    this.reEmitLicences(value);
  }

  @Watch('model.type')
  onLicencesChange() {
    const licences = this.model?.licences || [];
    if (!licences.length) {
      // selected option without licences, clear licences in model
      this.reEmitLicences([]);
    } else {
      // otherwise preselect required if not selected already
      this.reEmitLicences(licences.filter((licence: any) => licence.required));
    }
  }

  requiredOsValidator(val: any) {
    return val?.type ? true : this.$t('validation.field.required');
  }

  reEmit(model: any) {
    this.$emit('input', model);
  }

  reEmitLicences(licences: any[]) {
    this.$emit('update:licences', licences);
  }

  getKey(prop: string, key: string): string {
    this.getKey = getSnakedKeyFactory('product');
    return this.getKey(prop, key);
  }
}
