
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { cloneDeep } from 'lodash-es';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import WsSlider from '@WS_Components/WsSlider.vue';
import PricePeriod from '@/components/PricePeriod.vue';

@Component({
  components: {
    WsCheckbox,
    WsSlider,
    PricePeriod,
  },
})
export default class PropertySlider extends Vue {
  @Prop(String)
  readonly label!: string;
  @Prop({ default: () => ({}) })
  readonly value!: any;
  @Prop({ default: 12 })
  readonly period!: number;
  @Prop(Boolean)
  readonly inEdit!: boolean;
  propApplied = false;

  get model() {
    if (this.value.volume) {
      this.propApplied = true;
    }
    return { volume: 0, ...this.value };
  }
  set model(value: any) {
    this.reEmit(value);
  }

  get propConfig() {
    const model = this.model ?? {};

    return {
      unit: model.unit ?? 'GB',
      min: model.min,
      max: model.max,
      color: 'primary',
    };
  }

  get propPrice() {
    const price = cloneDeep(
      this.model?.price ??
        this.model?.prices?.find((p: any) => p.period === this.period) ??
        {}
    );
    price.period = price.period ?? this.period;

    if (price.actualPrice === null) {
      return price;
    }

    price.actualPrice *= this.model.volume || 1;
    return price;
  }

  @Watch('propApplied')
  onPropAppliedChanged(propApplied: boolean) {
    this.reEmit({
      ...this.model,
      volume: propApplied ? this.model?.volume || this.model?.min || 1 : 0,
    });
  }

  reEmit(value?: any) {
    this.$emit('input', value ?? this.model);
  }
}
